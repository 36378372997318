<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
            </div>
        </div>
        <!-- page-body -->
        <div v-if="isReady" class="page-body page-component">
            <!-- page-aside -->
            <aside class="page-aside">
                <nav class="snb">
                    <ol class="snb-list">
                        <li class="snb-item" :class="{'is-active': category === ''}" @click="selectCategory('')">
                            <a href="javascript:" class="snb-link"
                                >전체 <span class="sub">{{ getCateLength('') }}</span></a
                            >
                        </li>
                        <li
                            v-for="(cate, index) in categories"
                            class="snb-item"
                            :class="{'is-active': cate.prefix === category}"
                            :key="index"
                            @click="selectCategory(cate.prefix)">
                            <a href="javascript:" class="snb-link"
                                >{{ cate.name }} <span class="sub">{{ getCateLength(cate.prefix) }}</span></a
                            >
                        </li>
                    </ol>
                </nav>
            </aside>
            <!-- page-contents -->
            <div class="page-contents">
                <!-- board-list-container -->
                <div class="board-list-container">
                    <!-- board-list -->
                    <ul class="board-list">
                        <!-- board-item -->
                        <li class="board-item" v-for="(item, index) in renderItems" :key="index">
                            <div class="board">
                                <div class="board-column column-idx">{{ getPageNo(paging, index) }}</div>
                                <div class="board-column column-content">
                                    <p
                                        class="board-title swcc-text-skip swcc-cursor-underline swcc-cursor-pointer"
                                        :class="{'dev-text-cursor': session.manager}"
                                        :title="item.name"
                                        style="width: 600px"
                                        @click="goView(item.key)">
                                        {{ item.name }}
                                    </p>
                                    <div class="board-meta">
                                        <strong class="text">{{ item.category }}</strong>
                                        <span class="text">{{ timestampToDateFormat(item.updateDt, 'yyyy.MM.dd') }}</span>
                                        <span class="text">{{ convertToStorageBytes(item.size) }}</span>
                                        <div v-if="item.ext" class="badge">{{ item.ext }}</div>
                                    </div>
                                </div>
                                <div v-if="session.name !== null" class="board-column column-actions">
                                    <button class="btn-text" @click="downloadLake(item.key)"><span class="text">다운로드</span></button>
                                    <!-- 관리자가 아닌 회사 계정일 경우 lake => mart 로 copy가 가능하다. -->
                                    <button v-if="session.manager" class="btn-text" @click="goEdit(item.key)">
                                        <span class="text text-blue">상세정보 수정</span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- pagination -->
                    <CommonPagination :paging="{pageNo: paging.pageNo, pageSize: paging.pageSize, totalCount: filteredItems.length}" :pageFunc="pageFunc" />
                </div>
                <!-- board-list-container -->
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <!--        <div v-if="session.name !== null && showButton && !uploadToggle" class="page-bottom bottom-fixed" style="bottom: 16px">-->
        <!--            <div class="bottom-board-upload bottom-component">-->
        <!--                <button class="btn btn-primary" @click="uploadToggle = true"><span class="text">업로드</span></button>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-search-container bottom-component">
                    <div class="bottom-search">
                        <div class="search-column column-form">
                            <input v-model.trim="keyword" type="search" :placeholder="`${divisions.search} 검색하세요.`" @keyup.enter="searchKeyword" />
                        </div>
                        <div class="search-column column-button">
                            <button class="btn-icon" @click="searchKeyword"><i class="icon-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CommonMultiFileUpload
            v-if="uploadToggle && session.manager"
            v-model:toggle="uploadToggle"
            source="/v1/aws/s3/lakes/uploads"
            :select-options="[
                {name: '안전', value: 'safety'},
                {name: '복지', value: 'welfare'},
                {name: '환경', value: 'environment'},
                {name: '경제', value: 'economy'},
            ]"
            :use-unique-key="true"
            :use-select="true"
            path="origin"
            select-title="카테고리"
            :current-selected="category"
            @completed="initDataLakeList" />
    </main>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import CommonPagination from '@/components/common/CommonPagination';
import {useRoute, useRouter} from 'vue-router';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {convertToStorageBytes, getExt, getItems, getPageNo, getResult, isSuccess, lengthCheck, paginate, timestampToDateFormat} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {getValueByQuery} from '@/assets/js/route.utils';
import {useStore} from 'vuex';
import CommonMultiFileUpload from '@/components/common/CommonMultiFileUpload';
import {domainCode} from '@/assets/js/domain.code';
import {hideLoading, showLoading} from '@/assets/js/common.alert';

export default {
    name: 'DataLake',
    components: {CommonMultiFileUpload, CommonBreadcrumb, CommonPagination},
    setup: function () {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const session = computed(() => store.state.auth.session);

        const uploadToggle = ref(false);
        const isReady = ref(false);

        const currentQuery = computed(() => route.query);
        const categories = [
            {name: '안전', prefix: 'safety'},
            {name: '복지', prefix: 'welfare'},
            {name: '환경', prefix: 'environment'},
            {name: '경제', prefix: 'economy'},
        ];

        const divisions = reactive({
            root: computed(() => {
                if (route.name.includes('DataLakeOrigin')) {
                    return 'origin';
                } else if (route.name.includes('DataLakePublic')) {
                    return 'mart';
                }
                return '';
            }),
            search: computed(() => {
                if (route.name.includes('DataLakeOrigin')) {
                    return '데이터 레이크를';
                } else if (route.name.includes('DataLakePublic')) {
                    return '퍼블릭 데이터 마트를';
                }
                return '';
            }),
            isLake: computed(() => route.name.includes('DataLakeOrigin')),
        });

        const items = ref([]);
        const keyword = ref('');
        const search = ref(getValueByQuery(route.query, 'search', false, ''));
        const category = ref(getValueByQuery(route.query, 'category', false, ''));
        const paging = reactive({
            pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
            pageSize: 10,
            totalCount: 0,
        });

        const searchKeyword = () => {
            router.push({query: {...currentQuery.value, pageNo: 1, search: keyword.value}});
        };

        // const root = 'source';

        const checkAllFilter = item => {
            let result = false;
            for (let i = 0; i < categories.length; i++) {
                if (item.key.indexOf(`${divisions.root}/${categories[i].prefix}/`) === 0) {
                    result = true;
                    break;
                }
            }
            return result;
        };

        const getNameByKey = item => {
            // for (let i = 0; i < categories.length; i++) {
            //     const filter = `${root}/${categories[i].prefix}/`;
            //     if (item.key.indexOf(filter) === 0) {
            //         return item.key.replace(filter, '');
            //     }
            // }
            const refined = item.key.substring(item.key.lastIndexOf('/') + 1);
            const dotIndex = refined.lastIndexOf('.');
            if (dotIndex > -1) {
                return refined.substring(0, refined.lastIndexOf('.'));
            }
            return refined;
        };

        const getCategoryByKey = item => {
            for (let i = 0; i < categories.length; i++) {
                const filter = `${categories[i].prefix}/`;
                if (item.key.indexOf(filter) === 0) {
                    return categories[i].name;
                }
            }
            return '';
        };

        const filterKeyword = arr => {
            if (search.value) {
                return arr.filter(x => x.key && x.key.includes(search.value));
            }
            return arr;
        };

        const getFilteredItems = prefix => {
            if (prefix === '') {
                return filterKeyword(items.value.filter(x => x.key.slice(-1) !== '/' && checkAllFilter(x)));
            }
            const filter = `${divisions.root}/${prefix}/`;
            return filterKeyword(items.value.filter(x => x.key.indexOf(filter) === 0 && x.key.slice(-1) !== '/'));
        };

        const filteredItems = computed(() => {
            return getFilteredItems(category.value).map(x => ({
                ...x,
                name: getNameByKey(x),
                category: getCategoryByKey(x),
                ext: getExt(x.key, divisions.isLake),
            }));
            // if (category.value === '') {
            // }
            // const filter = `${divisions.root}/${category.value}/`;
            // return filterKeyword(items.value.filter(x => x.key.indexOf(filter) === 0 && x.key.slice(-1) !== '/')).map(x => ({
            //     ...x,
            //     name: getNameByKey(x),
            //     category: getCategoryByKey(x),
            //     ext: getExt(x.key),
            // }));
        });

        const getCateLength = prefix => {
            return getFilteredItems(prefix).length;
        };

        const renderItems = computed(() => paginate(filteredItems.value, paging.pageNo, paging.pageSize));

        const showButton = ref(true);
        // const toggleButtonHandler = () => {
        //     const scrollTop = window.scrollY;
        //     const innerHeight = window.innerHeight;
        //     const scrollHeight = document.body.scrollHeight;
        //     const isShow = !(scrollTop + innerHeight >= scrollHeight);
        //     if (showButton.value !== isShow) showButton.value = isShow;
        // };

        const selectCategory = prefix => {
            if (prefix !== category.value) {
                keyword.value = '';
                router.push({query: {category: prefix}});
            }
        };

        const pageFunc = pageNo => {
            router.push({query: {...currentQuery.value, pageNo: pageNo}});
        };

        const getDataLakeList = () => {
            ApiService.query('/v1/aws/s3/lakes', {prefix: divisions.root}).then(res => {
                if (lengthCheck(res)) {
                    items.value = getItems(res);
                    isReady.value = true;
                }
            });
        };

        const initDataLakeList = () => {
            getDataLakeList();
            router.push({query: {...currentQuery.value, pageNo: 1}});
        };

        const isLoading = ref(false);
        const downloadLake = uploadKey => {
            if (session.value.name === null) return;
            isLoading.value = true;

            ApiService.post('/v1/aws/s3/lakes/downloads', {uploadKey})
                .then(res => {
                    if (res) {
                        window.open(res);
                    }
                    isLoading.value = false;
                })
                .catch(e => {
                    console.error(e);
                    isLoading.value = false;
                });
        };

        // 현재 사용하지 않을 예정
        const deleteDataLake = uploadKey => {
            if (!session.value.manager) return;

            if (confirm('데이터 레이크를 삭제하시겠습니까? 삭제된 데이터는 복구할 수 없습니다.')) {
                ApiService.put('/v1/aws/s3/lakes', {uploadKey}).then(res => {
                    if (isSuccess(res)) {
                        initDataLakeList();
                    } else {
                        alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
                    }
                });
            }
        };

        const goView = metaKey => {
            showLoading();
            ApiService.post('/v1/aws/metas', {metaKey, metaTypeDcd: domainCode.META_TYPE_S3})
                .then(res => {
                    if (isSuccess(res) && res.metaNo > 0) {
                        router.push({name: `${route.name}View`, params: {metaNo: res.metaNo}});
                    } else {
                        hideLoading();
                        alert(getResult(res).message);
                    }
                })
                .catch(e => {
                    hideLoading();
                    console.error(e);
                    alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
                });
        };

        const goEdit = metaKey => {
            if (!session.value.manager) return;

            showLoading();
            ApiService.post('/v1/aws/metas', {metaKey, metaTypeDcd: domainCode.META_TYPE_S3})
                .then(res => {
                    if (isSuccess(res) && res.metaNo > 0) {
                        router.push({name: `${route.name}Edit`, params: {metaNo: res.metaNo}});
                    } else {
                        hideLoading();
                        alert(getResult(res).message);
                    }
                })
                .catch(e => {
                    hideLoading();
                    console.error(e);
                    alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
                });
        };

        watch(
            () => route.query,
            () => {
                if (route.name && route.name.includes('DataLake')) {
                    paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
                    category.value = getValueByQuery(route.query, 'category', false, '');
                    search.value = getValueByQuery(route.query, 'search', false, '');
                    // 실행 api 실행 됨
                    window.scrollTo(0, 0);
                }
            },
        );

        watch(
            () => route.name,
            () => {
                if (route.name && route.name.includes('DataLake')) {
                    getDataLakeList();
                }
            },
        );

        // onMounted(() => {
        //     document.addEventListener('scroll', toggleButtonHandler);
        // });
        //
        // onUnmounted(() => {
        //     document.removeEventListener('scroll', toggleButtonHandler);
        // });

        getDataLakeList();

        return {
            session,
            keyword,
            uploadToggle,
            isReady,
            categories,
            category,
            paging,
            filteredItems,
            renderItems,
            showButton,
            divisions,
            getCateLength,
            searchKeyword,
            getPageNo,
            pageFunc,
            selectCategory,
            initDataLakeList,
            downloadLake,
            deleteDataLake,
            goView,
            goEdit,
            timestampToDateFormat,
            convertToStorageBytes,
        };
    },
};
</script>
